<template>
    <div class="export_project_month">
        <el-button size="small" type="primary" @click="open" class="button_144px"
                   v-if="shi_project_project_power.area">导出项目月度报表
        </el-button>
        <el-dialog title="导出项目月度报表" :visible.sync="show" width="500px" @closed="$refs.ruleForm.resetFields()">
            <div class="content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="child_form">
                    <div class="body">
                        <el-form-item label="项目区域" prop="area">
                            <el-select size="medium" v-model="ruleForm.area" popper-class="global_select_popper"
                                       :disabled="shi_project_project_power.area>0">
                                <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="项目月度" prop="month">
                            <el-date-picker v-model="ruleForm.month" type="month" placeholder="选择月度"
                                            value-format="yyyy-MM" :clearable="false"></el-date-picker>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div class="button">
                <el-button class="button_98px" size="small" type="primary" @click="submit">确认导出</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from '@/api/methods/index';

    export default {
        data() {
            return {
                show: false, ruleForm: {area: '', month: ''},
                rules: {
                    area: [{required: true, message: '请选择导出项目区域', trigger: 'change'},],
                    month: [{required: true, message: '请选择导出项目月度', trigger: 'change'},],
                },
                areaList: [
                    {value: 0, label: '全部'},
                    {value:1, label:'州本级'},
                    {value:2, label:'玛沁县'},
                    {value:5, label:'甘德县'},
                    {value:6, label:'达日县'},
                    {value:4, label:'班玛县'},
                    {value:3, label:'久治县'},
                    {value:7, label:'玛多县'},
                ],
            }
        },
        computed: {
            shi_project_project_power() {
                return this.$store.state.user.current_user.shi_project_project_power
            }
        },
        methods: {
            open() {
                this.show = true;
                this.ruleForm = {area: '', month: ''};
                if (this.shi_project_project_power.area>0) {
                    this.ruleForm.area = this.shi_project_project_power.area;
                }
            },
            submit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        let param = {
                            time: this.ruleForm.month,
                        }
                        if (this.ruleForm.area) {
                            param.area = this.ruleForm.area
                        } else {
                            param.area = ''
                        }
                        let _url = window.location.href;
                        let __url = _url.split("#")[0];
                        let url = __url + "index.php/cqh/Project/monthLeadingOut/?token=" + this.$store.state.user.token + "&time=" + param.time + "&area=" + param.area;
                        let self = this;
                        api.project.monthLeadingOut(param).then(res => {
                            dd.biz.util.openLink({
                                url, //要打开链接的地址
                                onSuccess: function (result) {
                                    console.log('打开导出地址成功')
                                    self.show = false;
                                },
                                onFail: function (err) {
                                    console.log('打开导出地址失败', err)
                                    self.show = false;
                                }
                            });
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .export_project_month {
        display: inline-block;
        margin-right: 15px;

        .body {
            padding: 40px 40px 40px 0;

            .el-date-editor {
                width: 100%;
            }
        }

        .button {
            border-top: none !important;
        }
    }
</style>