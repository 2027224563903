<template>
    <el-dialog :title="title" :visible.sync="show" width="500px" custom-class="export_dialog"
               @closed="$refs.ruleForm.resetFields()">
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="child_form">
                <div class="body">
                    <el-form-item label="项目区域" prop="area">
                        <el-select size="medium" v-model="ruleForm.area" popper-class="global_select_popper"
                                   :disabled="shi_project_project_power.area>0">
                            <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="项目月度" prop="month">
                        <el-date-picker v-model="ruleForm.month" type="month" placeholder="选择月度"
                                        value-format="yyyy-MM" :clearable="false"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="合并小项目" prop="is_merge" v-if="showMerge">
                        <el-radio-group v-model="ruleForm.is_merge" style="margin-left: 0;">
                            <el-radio :label="1">合并</el-radio>
                            <el-radio :label="0">不合并</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="button">
            <el-button class="button_98px" size="small" type="primary" @click="submit">确认导出</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            api_name: '',//导出时请求的api name
            ruleForm: {area: '', month: '', is_merge: 0},
            rules: {
                area: [{required: true, message: '请选择导出项目区域', trigger: 'change'},],
                month: [{required: true, message: '请选择导出项目月度', trigger: 'change'},],
            },
            areaList: [
                {value: 0, label: '全部'},
                {value:1, label:'州本级'},
                {value:2, label:'玛沁县'},
                {value:5, label:'甘德县'},
                {value:6, label:'达日县'},
                {value:4, label:'班玛县'},
                {value:3, label:'久治县'},
                {value:7, label:'玛多县'},
            ],
        };
    },
    computed: {
        shi_project_project_power() {
            return this.$store.state.user.current_user.shi_project_project_power;
        },
        showMerge() {
            return ['monthLeadingOut','projectLeadingOut'].includes(this.api_name);
        },
        title() {
            let name = '';
            if (this.api_name == 'monthLeadingOut') {
                name = '导出项目月度报表';
            }
            if (this.api_name == 'projectLeadingOut') {
                name = '导出项目进度汇总表';
            }
            if (this.api_name == 'projectExportByQuality') {
                name = '导出项目实施进度表';
            }
            return name;
        }
    },
    methods: {
        submit() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let param = {
                        time: this.ruleForm.month,
                    };
                    if (this.ruleForm.area) {
                        param.area = this.ruleForm.area;
                    } else {
                        param.area = '';
                    }
                    if (this.showMerge) {
                        param.is_merge = this.ruleForm.is_merge;
                    }
                    this.$emit('exportSure', param, this.api_name);
                }
            });
        }
    }
};
</script>

<style lang="less">
.export_dialog {
    .body {
        padding: 40px 40px 40px 0;

        .el-date-editor {
            width: 100%;
        }

        .el-form-item {
            display: flex;
            align-items: center;

            .el-form-item__content {
                margin-left: 0!important;
                flex: 1;
            }
        }
    }

    .button {
        border-top: none !important;
    }
}


</style>