<template>
    <div class="btn_item add_project">
        <el-button size="small" type="primary" @click="open" class="button_98px"
                   v-if="$store.state.user.project_edit_data.project_edit==1">项目初始化
        </el-button>
        <el-dialog title="项目初始化" :visible.sync="show" width="1105px" :close-on-click-modal="false"
                   :close-on-press-escape="false" @closed="close">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="parent_form">
                <div class="row">
                    <div class="item long">
                        <el-form-item label="项目名称" prop="project_name">
                            <el-input v-model="ruleForm.project_name" placeholder="请输入项目名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目所属区域" prop="area">
                            <el-select size="medium" v-model="ruleForm.area" popper-class="global_select_popper"
                                       :disabled="shi_project_project_power.area>0">
                                <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目所在地" prop="address">
                            <el-input v-model="ruleForm.address" placeholder="请输入项目所在地"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="总投资金额" prop="all_invest_num">
                            <el-input-number v-model="ruleForm.all_invest_num" :step="1" :controls="false"
                                             placeholder="请输入总投资金额"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="实际投资金额" prop="real_num">
                            <el-input-number v-model="ruleForm.real_num" :step="1" :controls="false"
                                             placeholder="请输入实际投资金额"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="建设周期" prop="time">
                            <el-date-picker v-model="ruleForm.time" type="monthrange" range-separator="-"
                                            start-placeholder="选择日期" end-placeholder="选择日期"
                                            :clearable="false" value-format="yyyy-MM"
                                            @change="timeChange"></el-date-picker>
                        </el-form-item>
                    </div>
                    <!--<div class="item">
                        <el-form-item label="立项前期费用">
                            <el-input-number v-model="ruleForm.project_brfore_num" :step="1" :controls="false" placeholder="请输入立项前期费用"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>-->
                    <div class="item" v-for="(year,index) in years" :key="index">
                        <el-form-item :label="year+'年投资金额'" prop="year_invest_num">
                            <el-input-number v-model="ruleForm.year_invest_num[year]" :step="1" :controls="false"
                                             placeholder="请输入年投资金额"
                                             @change="$refs.ruleForm.validateField('year_invest_num')"></el-input-number>
                            <div class="unit">万元</div>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="建设性质" prop="build_quality">
                            <!--<div class="radio_group">
                                <div :class="{radio:true,active:ruleForm.build_quality===item.value}" v-for="item in buildQuality" :key="item.value" @click="ruleForm.build_quality=item.value">
                                    <div class="icon">
                                        <img class="default" src="../../assets/img/radio.png" alt="">
                                        <img class="active" src="../../assets/img/radio_active.png" alt="">
                                    </div>
                                    <div class="label">{{item.label}}</div>
                                </div>
                            </div>-->
                            <el-select size="medium" v-model="ruleForm.build_quality"
                                       popper-class="global_select_popper">
                                <el-option v-for="item in buildQuality" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目性质" prop="project_quality">
                            <el-select size="medium" v-model="ruleForm.project_quality"
                                       popper-class="global_select_popper">
                                <el-option v-for="item in projectQuality" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目类别" prop="project_genre">
                            <el-select size="medium" v-model="ruleForm.project_genre"
                                       popper-class="global_select_popper">
                                <el-option v-for="item in projectGenre" :key="item.value" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="item long">
                        <el-form-item label="项目概况" prop="project_profile">
                            <el-input type="textarea" :rows="4" v-model="ruleForm.project_profile"
                                      placeholder="请输入项目概况"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="联络小组" prop="contact_group">
                            <span>{{ ruleForm.contact_group.name || '' }}</span>
                            <el-button type="text" @click="chooseDepartment" style="margin-left: 5px;">选择部门</el-button>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="联络小组负责人" prop="contact_group_fuze">
                            <span>{{ ruleForm.contact_group_fuze.nickname || '' }}</span>
                            <el-button type="text" @click="choosePeople" style="margin-left: 5px;">选择联络小组负责人</el-button>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目负责单位" prop="project_unit">
                            <el-input v-model="ruleForm.project_unit" placeholder="请输入项目负责单位名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目负责人" prop="project_fuze">
                            <el-input v-model="ruleForm.project_fuze" placeholder="请输入项目负责人名称"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="负责人电话" prop="fuze_phone">
                            <el-input v-model="ruleForm.fuze_phone" placeholder="请输入项目负责人电话"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="项目分管领导">
                            <el-input v-model="ruleForm.fgld" placeholder="请输入项目分管领导"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item label="分管领导电话" prop="fgld_phone">
                            <el-input v-model="ruleForm.fgld_phone" placeholder="请输入项目分管领导电话"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item long child">
                        <span>子项目</span>
                        <addChildProject :years="years" :parent_form="ruleForm"></addChildProject>
                    </div>
                    <div class="item long table" v-if="ruleForm.child_project_info.length>0">
                        <el-table :data="ruleForm.child_project_info">
                            <el-table-column type="index" label="序号" align="center"></el-table-column>
                            <el-table-column property="child_name" label="标题" align="center"></el-table-column>
                            <el-table-column label="投资金额" align="center">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.child_all_invest_num }}万元</span>
                                </template>
                            </el-table-column>
                            <el-table-column v-for="(year,index) in years" :label="year+'年投资金额'" :key="index"
                                             align="center">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.child_year_invest_num[year] }}万元</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <editChildProject :years="years" :parent_form="ruleForm" :child_form="scope.row"
                                                          :index="scope.$index"></editChildProject>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="button">
                    <el-button class="button_88px" size="small" type="primary" @click="submitForm">保存</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import addChildProject from '@c/project/child_project/add_child_project.vue';
import count from '@/utils/count.js';
import peoplePicker from '@/utils/people_picker';
import editChildProject from '@c/project/child_project/edit_child_project.vue';

export default {
    components: {addChildProject, editChildProject},
    data() {
        const check_all_invest_num = (rule, value, callback) => {
            if (value === 0) {
                callback(new Error('总投资金额不能为0'));
            } else {
                callback();
            }
        };
        const check_year_invest_num = (rule, value, callback) => {
            if (value) {
                let arr = [];
                this.years.forEach(val => {
                    if (this.ruleForm.year_invest_num[val] !== undefined && this.ruleForm.year_invest_num[val] !== null && this.ruleForm.year_invest_num[val] !== '') {
                        arr.push(this.ruleForm.year_invest_num[val]);
                    }
                });
                if (arr.length === 0) {
                    callback(new Error('请输入年投资金额'));
                }
                if (arr.length !== this.years.length) {
                    callback(new Error('请输入每年投资金额'));
                }
                callback();
            } else {
                callback();
            }
        };
        const check_phone_number = (rule, value, callback) => {
            if (value && !(/^(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(value))) {
                callback(new Error('手机号码有误，请重填'));
            } else {
                callback();
            }
        };
        return {
            show: false,
            areaList: [
                {value:1, label:'州本级'},
                {value:2, label:'玛沁县'},
                {value:5, label:'甘德县'},
                {value:6, label:'达日县'},
                {value:4, label:'班玛县'},
                {value:3, label:'久治县'},
                {value:7, label:'玛多县'},
            ],
            buildQuality: [
                {value: 1, label: '新建'},
                {value: 2, label: '续建'},
                {value: 3, label: '其它'},
                {value: 4, label: '完工未竣工'},
                {value: 5, label: '跨年'},
            ],
            projectQuality: [
                {value: 1, label: '固定资产投资类'},
                {value: 2, label: '非固定资产投资类'},
                // {value: 3, label: '其他'}
            ],
            projectGenre: [
                {value: 1, label: '保障和改善民生'},
                {value: 2, label: '文化教育支援'},
                {value: 3, label: '产业支援促进就业'},
                {value: 4, label: '智力支援'},
                {value: 5, label: '交往交流交融'},
                // {value:6,label:'基层组织和政权建设'}
            ],
            ruleForm: {
                project_name: '',
                area: '',
                address: '',
                all_invest_num: undefined,
                real_num: undefined,
                time: '',
                // project_brfore_num:undefined,
                year_invest_num: {
                    [new Date().getFullYear()]: undefined
                },
                build_quality: 1,
                project_quality: '',
                project_genre: '',
                project_profile: '',
                contact_group: '',
                contact_group_fuze: '',
                project_unit: '',
                project_fuze: '',
                fuze_phone: '',
                child_project_info: [],
                fgld: '',
                fgld_phone: ''
            },
            rules: {
                project_name: [{required: true, message: '请输入项目名称', trigger: 'blur'}],
                area: [{required: true, message: '请选择项目所属区域', trigger: 'change'}],
                address: [{required: true, message: '请输入项目所在地', trigger: 'blur'}],
                all_invest_num: [
                    {required: true, message: '请输入总投资金额', trigger: 'blur'},
                    // {validator:check_all_invest_num, trigger: 'blur'}
                ],
                // real_num:[{ required: true, message: '请输入实际投资金额', trigger: 'blur' },],
                time: [{required: true, message: '请选择建设周期', trigger: 'change'}],
                year_invest_num: [
                    {required: true, message: '请输入年投资金额', trigger: 'blur'},
                    {validator: check_year_invest_num, trigger: 'blur'}
                ],
                project_quality: [{required: true, message: '请选择项目性质', trigger: 'change'}],
                project_genre: [{required: true, message: '请选择项目类别', trigger: 'change'}],
                project_profile: [{required: true, message: '请输入项目概况', trigger: 'blur'}],
                // contact_group: [{required: true, message: '请选择联络小组部门', trigger: 'change'}],
                // contact_group_fuze: [{required: true, message: '请选择联络小组负责人', trigger: 'change'},],
                // project_unit: [{required: true, message: '请输入项目负责单位名称', trigger: 'blur'}],
                // project_fuze: [{required: true, message: '请输入项目负责人名称', trigger: 'blur'}],
                // fuze_phone: [
                //     {required: true, message: '请输入项目负责人电话', trigger: 'blur'},
                //     {validator: check_phone_number, trigger: 'blur'}
                // ],
                fgld_phone: [{validator: check_phone_number, trigger: 'blur'}]
            },
            years: [new Date().getFullYear()],//用于获取当前选择建设周期的年份跨度
        };
    },
    computed: {
        shi_project_project_power() {
            return this.$store.state.user.current_user.shi_project_project_power;
        }
    },
    methods: {
        open() {
            if (this.shi_project_project_power.area > 0) {
                this.ruleForm.area = this.shi_project_project_power.area;
            }
            this.show = true;
        },
        //建设周期选择
        timeChange(value) {
            if (value) {
                // this.ruleForm.year_invest_num={};
                this.years = [];
                let start = Number(value[0].slice(0, 4));
                let end = value[1].slice(0, 4);
                for (let i = start; i <= end; i++) {
                    this.years.push(i);
                    // this.$set(this.ruleForm.year_invest_num,i,undefined)
                    // //如果有子项目清空掉子项目的年度投资金额
                    // if(this.ruleForm.child_project_info.length>0){
                    //     this.ruleForm.child_project_info.forEach(item=>{
                    //         item.child_year_invest_num={[i]:undefined};
                    //         // this.$set(item.child_year_invest_num,i,undefined)
                    //     })
                    // }
                }
            }
        },
        //提交项目项目初始化表单 附加条件验证
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const h = this.$createElement;
                    let message = [];//弹出的提示内容

                    //年度投资金额总和不等于总投资金额
                    // let year_invest_num = count.sumObj(this.ruleForm.year_invest_num);//年度投资金额
                    // if(year_invest_num!==this.ruleForm.all_invest_num){
                    //     message.push(h('p', null, `${message.length+1}.年度投资金额总和 不等于 总投资金额`))
                    // }

                    // if(this.ruleForm.child_project_info.length>0){
                    //子项目的年度投资金额是否全部填完
                    // for(let child of this.ruleForm.child_project_info){
                    //     for(let year in child.child_year_invest_num){
                    //         if(!child.child_year_invest_num[year]){
                    //             this.$message.error('子项目有未填的年度投资金额');
                    //             return;
                    //         }else{
                    //             child[year]=child.child_year_invest_num[year];
                    //         }
                    //     }
                    // }
                    //子项目年度投资金额总和不等于子项目投资金额
                    // for(let child of this.ruleForm.child_project_info){
                    //     if(count.sumObj(child.child_year_invest_num)!==child.child_all_invest_num){
                    //         message.push(h('p', null, `${message.length+1}.子项目(${child.child_name}) 年度投资金额总和 不等于 子项目投资金额`))
                    //     }
                    // }

                    //子项目投资金额总和不等于母项目总投资金额
                    // let child_all_invest_num = count.sum(this.ruleForm.child_project_info,'child_all_invest_num');//子项目投资金额总和
                    // if(child_all_invest_num!==this.ruleForm.all_invest_num){
                    //     message.push(h('p', null, `${message.length+1}.所有 子项目投资金额总和 不等于 母项目总投资金额`))
                    // }

                    //子项目某某年投资金额总和不等于母项目某某年度投资金额
                    // for(let year in this.ruleForm.year_invest_num){
                    //     if(this.ruleForm.year_invest_num[year]!==count.sum(this.ruleForm.child_project_info,year)){
                    //         message.push(h('p', null, `${message.length+1}.所有 子项目${year}年投资金额总和 不等于 母项目${year}年度投资金额`))
                    //     }
                    // }

                    // }

                    if (this.ruleForm.child_project_info.length > 0) {
                        //子项目的年度投资金额是否全部填完
                        let check = true;
                        for (let child of this.ruleForm.child_project_info) {
                            if (child.child_all_invest_num === undefined || child.child_all_invest_num === null || child.child_all_invest_num === '') {
                                this.$message({message: '子项目有未填的投资金额', type: 'warning'});
                                check = false;
                                // return;
                            }
                            this.years.forEach(year => {
                                if (child.child_year_invest_num[year] === undefined || child.child_year_invest_num[year] === null || child.child_year_invest_num[year] === '') {
                                    this.$message({message: '子项目有未填的年度投资金额', type: 'warning'});
                                    check = false;
                                    // return;
                                } else {
                                    child[year] = child.child_year_invest_num[year];
                                }
                            });
                        }
                        if (!check) {
                            return;
                        }
                    }
                    //验证有误后弹出以下确认信息
                    if (message.length > 0) {
                        this.$msgbox({
                            title: '项目信息待确认',
                            message: h('p', null, message),
                            showCancelButton: true,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            beforeClose: (action, instance, done) => {
                                if (action === 'confirm') {
                                    this.addProject();
                                    done();
                                } else {
                                    done();
                                }
                            }
                        }).catch(() => {

                        });
                    } else {
                        this.addProject();
                    }
                }
            });
        },
        //调用添加项目api
        addProject() {
            let param = JSON.parse(JSON.stringify(this.ruleForm));
            delete param.time;
            param.start_time = this.ruleForm.time[0];
            param.end_time = this.ruleForm.time[1];
            param.child_project_info = [];
            param.contact_group = param.contact_group.department_id;
            param.contact_group_fuze = param.contact_group_fuze.userid;
            param.all_invest_num = param.all_invest_num * 10000;
            // param.real_num=param.real_num*10000;
            param.is_change = 1;
            if (param.real_num === '' || param.real_num === undefined || param.real_num === null) {
                param.real_num = param.all_invest_num;
            } else {
                param.real_num = param.real_num * 10000;
            }
            // if(param.project_brfore_num===undefined||param.project_brfore_num===null||param.project_brfore_num===''){
            //     delete param.project_brfore_num;
            // }else{
            //     param.project_brfore_num=param.project_brfore_num*10000;
            // }
            for (let year in param.year_invest_num) {
                param.year_invest_num[year] = Number(param.year_invest_num[year]) * 10000;
            }
            param.year_invest_num = JSON.stringify(param.year_invest_num);
            let child_project_info = JSON.parse(JSON.stringify(this.ruleForm.child_project_info));
            child_project_info.forEach(item => {
                item.child_all_invest_num = Number(item.child_all_invest_num) * 10000;
                for (let year in item.child_year_invest_num) {
                    item.child_year_invest_num[year] = Number(item.child_year_invest_num[year]) * 10000;
                }
                param.child_project_info.push({
                    child_name: item.child_name,
                    child_all_invest_num: item.child_all_invest_num,
                    child_year_invest_num: item.child_year_invest_num
                });
            });
            param.child_project_info = JSON.stringify(param.child_project_info);
            api.project.addProject(param).then(res => {
                if (res.data.state == 1) {
                    this.$message({
                        message: '项目初始化成功',
                        type: 'success'
                    });
                    this.show = false;
                    this.$parent.page = 1;
                    this.$parent.projectList(true);
                }
            });
        },
        close() {
            this.ruleForm = {
                project_name: '',
                area: '',
                address: '',
                all_invest_num: undefined,
                real_num: undefined,
                time: '',
                // project_brfore_num:undefined,
                year_invest_num: {
                    [new Date().getFullYear()]: undefined
                },
                build_quality: 1,
                project_quality: '',
                project_genre: '',
                project_profile: '',
                contact_group: '',
                contact_group_fuze: '',
                project_unit: '',
                project_fuze: '',
                fuze_phone: '',
                child_project_info: [],
                fgld: '',
                fgld_phone: ''
            };
            this.years = [new Date().getFullYear()];
            this.$refs.ruleForm.resetFields();
        },
        //选择联络小组
        chooseDepartment() {
            peoplePicker.departmentPicker([], (res) => {
                let info = {};
                info.department_id = res.departments[0].id;
                info.name = res.departments[0].name;
                this.ruleForm.contact_group = info;
            });
        },
        //选择联络小组负责人
        choosePeople() {
            peoplePicker.peoplePicker([], (res) => {
                let info = {};
                info.avatar = res.users[0].avatar;
                info.nickname = res.users[0].name;
                info.userid = res.users[0].emplId;
                this.ruleForm.contact_group_fuze = info;
            });
        }
    }
};
</script>

<style scoped lang="less">
.add_project {
    //margin-left: 20px;
    //display: inline-block;

    .row {
        .child {
            padding-left: 72px;
        }

        .table {
            padding-left: 30px;
        }
    }
}
</style>