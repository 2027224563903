<template>
    <div class="main project">
        <ul class="tabs">
            <li>项目列表</li>
        </ul>
        <div class="container" v-show="!show_sub">
            <div class="search_filter">
                <div class="item">
                    <div class="left">所属年度</div>
                    <el-date-picker size="medium" v-model="year" type="year" value-format="yyyy"
                                    placeholder="项目年度"
                                    @change="search"></el-date-picker>
                </div>
                <div class="item">
                    <div class="left">所属地区</div>
                    <el-select size="medium" v-model="area" popper-class="global_select_popper" @change="search"
                               :disabled="shi_project_project_power.area>0">
                        <el-option v-for="item in areaList" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <div class="left">建设性质</div>
                    <el-select size="medium" v-model="build_quality" popper-class="global_select_popper"
                               @change="search">
                        <el-option v-for="item in buildQuality" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="item">
                    <div class="left">项目性质</div>
                    <el-select size="medium" v-model="project_quality" popper-class="global_select_popper"
                               @change="search">
                        <el-option v-for="item in projectQuality" :key="item.value" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </div>
                <!--<el-button size="small" type="primary" plain class="button_68px" @click="search">查询</el-button>-->
                <addProject ref="addProject"/>
                <div class="btn_item export" v-if="shi_project_project_power.area">
                    <el-popover placement="bottom" width="150" :open-delay="200" trigger="hover" :visible-arrow="false"
                                popper-class="operation_popover" transition="el-zoom-in-top">
                        <ul>
                            <li @click="exportShow('monthLeadingOut')">导出项目月度报表</li>
                            <li @click="exportShow('projectLeadingOut')">导出项目进度汇总表</li>
                            <li @click="exportShow('projectNumOut')">按类别导出项目</li>
                            <li @click="exportShow('projectExportByQuality')">导出项目实施进度表</li>
                        </ul>
                        <el-button slot="reference" size="small" type="primary" class="button_88px">项目导出</el-button>
                    </el-popover>
                </div>
                <!--                <div class="export" v-if="false">-->
                <!--                    <exportProjectMonth/>-->
                <!--                    <exportProjectAll/>-->
                <!--                    <el-button size="small" type="primary" class="button_144px"-->
                <!--                               v-if="shi_project_project_power.area" @click="exportProject">-->
                <!--                        按类别导出项目-->
                <!--                    </el-button>-->
                <!--                </div>-->
            </div>
            <div class="father_table" v-loading="loading">
                <div class="list_item" v-for="(v,k) in list.filter(a=>a.project&&a.project.length)" :key="'list'+k">
                    <div class="item_genre_title" :class="{open:sub_show[v.project_genre]}"
                         @click="sub_show[v.project_genre]=!sub_show[v.project_genre]">
                        <div class="title_label">{{ initProjectGenre(v.project_genre) }}（{{ getChildCount(v.project) }}）</div>
                        <div class="right_icon"></div>
                    </div>
                    <div class="item bg-active" v-show="sub_show[v.project_genre]" v-for="(item,index) in v.project"
                         :key="'item'+index" @click="chooseProject(item)">
                        <div class="title_label">{{ item.project_name }}（{{ item.children.length }}）</div>
                        <div class="area">所属区域：<span style="color: #07ba3d;">{{ initArea(item.area) }}</span></div>
                        <div class="right_icon"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-show="show_sub">
            <div class="genre_title">
                <span class="genre" @click="show_sub=false">{{
                        initProjectGenre(choose_father_project.project_genre)
                    }}</span>
                <span class="icon"></span>
                <span class="title">【{{ initArea(choose_father_project.area) }}】{{
                        choose_father_project.project_name
                    }}</span>
            </div>
            <div class="table">
                <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                    <el-table-column type="index" label="序号" align="center">
                        <!--                        <template slot-scope="scope">-->
                        <!--                            <span :style="{color:apiReturnColor(scope.row.color)}">{{ scope.row.project_number }}</span>-->
                        <!--                        </template>-->
                    </el-table-column>
                    <el-table-column label="项目类别" align="center">
                        <template slot-scope="scope">
                            <span>{{ initProjectGenre(scope.row.project_genre) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目概况" align="center">
                        <template slot-scope="scope">
                            <div class="project_profile">{{ scope.row.project_profile }}</div>
                        </template>
                    </el-table-column>
                    <!--<el-table-column prop="project_fuze" label="项目负责人" align="center"></el-table-column>-->
                    <el-table-column label="建设性质" align="center">
                        <template slot-scope="scope">
                            <span>{{ initBuildQuality(scope.row.build_quality) }}<!--<span
                                    v-if="scope.row.build_quality==2&&scope.row.start_time&&scope.row.end_time">({{scope.row.start_time.slice(0,4)}}年{{scope.row.start_time.slice(5,7)}}月-{{scope.row.end_time.slice(0,4)}}年{{scope.row.end_time.slice(5,7)}}月)</span>--></span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column label="项目性质" align="center">
                        <template slot-scope="scope">
                            <span>{{initProjectQuality(scope.row.project_quality)}}</span>
                        </template>
                    </el-table-column>-->
                    <el-table-column label="总投资金额(万元)" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.all_invest_num">{{
                                    Number(scope.row.all_invest_num) / 10000
                                }}万元</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="填报情况" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.project_status==5||scope.row.project_status==6">已完工</span>
                            <span v-else>
                                <!--暂时固定显示横杠, 后续再开起来-->
                                <!--<span>-</span>-->
                                <span v-if="scope.row.has_month_form"
                                      style="color:#0db829;">{{ scope.row.current_month }}月已填报</span>
                                <span v-else style="color:#ff6a6a;">
                                    <span
                                        v-if="scope.row.current_month!=1&&scope.row.current_month!=2&&scope.row.current_month!=3">{{
                                            scope.row.current_month
                                        }}月未填报</span>
                                    <span v-else style="color: #666">--</span>
                                </span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目拨付款(万元)" align="center">
                        <template slot-scope="scope">
                            <span
                                v-if="scope.row.app_money!==null&&scope.row.app_money!==undefined&&scope.row.app_money!==''">{{
                                    Number(scope.row.app_money) / 10000
                                }}万元</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="项目进度" align="center" width="165">
                        <template slot-scope="scope">
                            <div class="progress">
                                <el-progress :stroke-width="12"
                                             :percentage="isNaN(Number(scope.row.year_project_evolve_rate))?0:Number(scope.row.year_project_evolve_rate)"
                                             :color="apiReturnColor(scope.row.color)"></el-progress>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <projectDetail :enter="1" :project_detail="scope.row"
                                           @refresh="projectList"></projectDetail>
                        </template>
                    </el-table-column>
                </el-table>
                <!--                <div class="pagination">-->
                <!--                    <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize"-->
                <!--                                   layout="total, prev, pager, next, jumper" :total="total"></el-pagination>-->
                <!--                </div>-->
            </div>
        </div>
        <exportDialog ref="exportDialog" @exportSure="exportSure"></exportDialog>
    </div>
</template>

<script>
import api from '@/api/methods/index';
import addProject from '@c/project/add_project.vue';
import computedData from '@/mixins/computedData.js';
import projectDetail from '@c/project/project_detail.vue';
import customColor from '@/mixins/customColor.js';
import exportProjectMonth from '@c/project/export/export_project_month.vue';
import exportProjectAll from '@c/project/export/export_project_all.vue';
import exportDialog from "../../components/project/export/export_dialog.vue";
import count from '@/utils/count';
import globalYear from '@/mixins/global_year';

export default {
    components: {addProject, projectDetail, exportProjectMonth, exportProjectAll, exportDialog},
    mixins: [globalYear, computedData, customColor],
    data() {
        return {
            // year: new Date().getFullYear().toString(),
            areaList: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '州本级'},
                {value: 2, label: '玛沁县'},
                {value: 5, label: '甘德县'},
                {value: 6, label: '达日县'},
                {value: 4, label: '班玛县'},
                {value: 3, label: '久治县'},
                {value: 7, label: '玛多县'},
            ],
            area: 0,
            build_quality: 0,
            buildQuality: [
                {value: 0, label: '全部(默认)'},
                {value: 1, label: '新建'},
                {value: 2, label: '续建'},
                {value: 3, label: '其它'},
                {value: 4, label: '完工未竣工'},
                {value: 5, label: '跨年'},
            ],
            project_quality: '',
            projectQuality: [
                {value: '', label: '全部(默认)'},
                {value: '1', label: '固定资产投资类'},
                {value: '2,3', label: '非固定资产投资类'},
            ],
            tableData: [],
            page: 1, pagesize: 1000, total: 0,
            loading: false,
            show_sub: false,//显示子项目页面
            choose_father_project: {
                // project_name: '',//项目名称
                // area: '',//所属地区
                // project_genre:'',//项目类别
                // children: [],//项目名称和所属地区都相同的项目
            },//选中的父级项目信息
            list: [],//父级list
            sub_show: {//展开子项目
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            },
            oldTableData: [],
        };
    },
    computed: {
        shi_project_project_power() {
            return this.$store.state.user.current_user.shi_project_project_power;
        },
        current_userid() {
            return this.$store.state.user.current_user.userid;
        }
    },
    created() {
        console.log(this.$route.query);
        if (this.$route.query.build_quality) {
            this.build_quality = Number(this.$route.query.build_quality);
        }
        if (this.$route.query.area) {
            this.area = Number(this.$route.query.area);
        }
        if (this.shi_project_project_power.area > 0) {
            this.area = this.shi_project_project_power.area;
        }
        this.projectList();
    },
    methods: {
        projectList(refresh = false) {
            this.loading = !refresh;
            let param = {page: this.page, pagesize: this.pagesize};
            if (this.year) param.year = this.year;
            if (this.area) param.area = this.area;
            if (this.build_quality) param.build_quality = this.build_quality;
            if (this.project_quality) param.project_quality = this.project_quality;
            if (!this.shi_project_project_power.area) {
                param.fuze_users = this.current_userid;
            }
            if (this.$route.query.project_genre) {
                param.project_genre = Number(this.$route.query.project_genre);
            }
            if (this.$route.query.project_quality) {
                param.project_quality = Number(this.$route.query.project_quality);
            }
            if (this.$route.query.project_status) {
                param.project_status = this.$route.query.project_status;
            }
            if (this.$route.query.is_statistics) {
                param.is_statistics = 1;
            }
            // if (this.shi_project_project_power.area === 1) {
            //     param.fuze_users = this.current_userid;
            // }
            api.project.projectList(param).then(res => {
                let no_group_list = [];
                if (res.data) {
                    this.total = res.data.count || 0;
                    no_group_list = res.data.lists || [];
                    no_group_list.forEach(project => {
                        project.current_year = count.com_current_month(25).year;
                        project.current_month = count.com_current_month(25).month;//当前需要填报的月度,以每月20号为基准,如4月21号0点以后, 显示4月是否填报.之前显示3月是否填报
                        project.has_month_form = false;
                        project.form_info = [];
                        if (res.data.form_info && res.data.form_info.length > 0) {
                            res.data.form_info.forEach(form => {
                                if (project.project_id === form.project_id) {
                                    project.form_info.push(form.month);
                                    if (new Date(form.month).getFullYear() === project.current_year && new Date(form.month).getMonth() + 1 === project.current_month) {
                                        project.has_month_form = true;
                                    }
                                }
                            });
                        }
                    });
                    no_group_list.forEach(project => {
                        project.form_info.sort((a, b) => {
                            return (new Date(a) < new Date(b)) ? -1 : (new Date(a) > new Date(b)) ? 1 : 0;
                        });
                        if (project.has_month_form && project.form_info.length > 0) {
                            project.current_month = new Date(project.form_info[project.form_info.length - 1]).getMonth() + 1;//最后一个填报月份
                        }
                    });
                    //填报情况显示逻辑:
                    //如果基准月未填报, 显示基准月未填报.
                    //如果基准月已填报, 则显示最后一个填报月份.
                    //如基准月为5月, 如果5月没有填报, 则优先显示"5月未填报", 如果5,6月已填报, 则显示"6月已填报"
                }
                this.loading = false;
                this.oldTableData = JSON.parse(JSON.stringify(no_group_list));
                this.list = [
                    {
                        project_genre: 1,//项目类别
                        project: [
                            // {
                            //     project_name:'',//项目名称
                            //     area:'',//所属地区
                            //     project_genre:'',
                            //     children:[],//项目名称和所属地区都相同的项目
                            // }
                        ]
                    },
                    {
                        project_genre: 2,
                        project: []
                    },
                    {
                        project_genre: 3,
                        project: []
                    },
                    {
                        project_genre: 4,
                        project: []
                    },
                    {
                        project_genre: 5,
                        project: []
                    },
                    {
                        project_genre: 6,
                        project: []
                    },
                ];
                no_group_list.forEach(item => {
                    if (!isNaN(Number(item.project_genre))) {
                        let info_i = this.list[Number(item.project_genre) - 1].project.findIndex(a => a.project_name == item.project_name && a.area == item.area);
                        if (info_i !== -1) {//同项目名称和同地区的数据已存在
                            this.list[Number(item.project_genre) - 1].project[info_i].children.push(item);
                        } else {
                            this.list[Number(item.project_genre) - 1].project.push({
                                project_name: item.project_name,
                                area: item.area,
                                project_genre: item.project_genre,
                                children: [item]
                            });
                        }
                    }
                });
                let edit_info = this.$store.state.user.edit_project_info;
                if (edit_info) {
                    let info = no_group_list.find(a => a.project_id == edit_info.project_id);
                    if (info) {
                        this.choose_father_project.project_name = edit_info.project_name;
                    }
                }
                this.tableData = no_group_list.filter(a => a.project_name == this.choose_father_project.project_name && a.area == this.choose_father_project.area);

                console.log(this.list);
            });
        },
        search() {
            this.page = 1;
            this.show_sub = false;
            this.oldTableData = [];
            this.tableData = [];
            this.list = [];
            this.choose_father_project = {};
            this.sub_show = {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
            };
            this.projectList();
        },
        pageChange(page) {
            this.page = page;
            this.projectList();
        },
        showDialog(component_name, row, type = '', edit) {
            this.$refs[component_name].show = true;
            this.$refs[component_name].project_detail = row;
            if (type) this.$refs[component_name].type = type;
            if (edit !== undefined) this.$refs[component_name].edit = edit;
        },
        //终止项目
        stopProject(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要终止该项目吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.project.stopProject({project_id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '项目终止成功',
                                    type: 'success'
                                });
                                this.projectList();
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        deleteProject(id) {
            const h = this.$createElement;
            this.$msgbox({
                title: '提示',
                message: h('p', null, '确定要删除该项目吗?'),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        api.project.deleteProject({project_id: id}).then(res => {
                            if (res.data.state == 1) {
                                this.$message({
                                    message: '项目删除成功',
                                    type: 'success'
                                });
                                this.projectList();
                            }
                        });
                        done();
                    } else {
                        done();
                    }
                }
            }).catch(() => {

            });
        },
        //按类别导出项目
        exportProject() {
            let param = {};
            let _url = window.location.href;
            let __url = _url.split("#")[0];
            let url = __url + "index.php/cqh/Project/projectNumOut/?token=" + this.$store.state.user.token;
            if (this.year) {
                param.year = this.year;
                url = url + "&year=" + param.year;
            }
            if (this.area) {
                param.area = this.area;
                url = url + "&area=" + param.area;
            }
            let self = this;
            api.project.projectNumOut(param).then(res => {
                dd.biz.util.openLink({
                    url, //要打开链接的地址
                    onSuccess: function (result) {
                        console.log('打开导出地址成功');
                        self.show = false;
                    },
                    onFail: function (err) {
                        console.log('打开导出地址失败', err);
                        self.show = false;
                    }
                });
            });
        },
        //导出选择框显示
        exportShow(api_name = '') {
            if (api_name == 'projectNumOut') {//按类别导出项目
                this.exportProject();
            } else {
                if (this.$refs.exportDialog) {
                    this.$refs.exportDialog.show = true;
                    this.$refs.exportDialog.api_name = api_name;
                }
            }
        },
        exportSure(param, api_name) {
            let _url = window.location.href;
            let __url = _url.split("#")[0];
            let url = __url + `index.php/cqh/${api_name == 'projectExportByQuality' ? 'Export' : 'Project'}/${api_name}/?token=` + this.$store.state.user.token + "&time=" + param.time + "&area=" + param.area;
            if (api_name == 'monthLeadingOut') {//导出项目月度报表
                api.project.monthLeadingOut(param).then(res => {
                    dd.biz.util.openLink({
                        url, //要打开链接的地址
                        onSuccess: function (result) {
                            console.log('打开导出地址成功');
                        },
                        onFail: function (err) {
                            console.log('打开导出地址失败', err);
                        }
                    });
                });
            }
            if (api_name == 'projectLeadingOut') {//导出项目进度汇总表
                api.project.projectLeadingOut(param).then(res => {
                    dd.biz.util.openLink({
                        url, //要打开链接的地址
                        onSuccess: function (result) {
                            console.log('打开导出地址成功');
                        },
                        onFail: function (err) {
                            console.log('打开导出地址失败', err);
                        }
                    });
                });
            }
            if (api_name == 'projectExportByQuality') {//导出项目实施进度表
                api.project.projectExportByQuality(param).then(res => {
                    dd.biz.util.openLink({
                        url, //要打开链接的地址
                        onSuccess: function (result) {
                            console.log('打开导出地址成功');
                        },
                        onFail: function (err) {
                            console.log('打开导出地址失败', err);
                        }
                    });
                });
            }
        },
        chooseProject(v) {
            this.choose_father_project = JSON.parse(JSON.stringify(v));
            this.$store.commit('user/SETEDITPROJECTINFO', {});
            this.show_sub = true;
            let edit_info = this.$store.state.user.edit_project_info;
            if (edit_info) {
                let info = this.oldTableData.find(a => a.project_id == edit_info.project_id);
                if (info) {
                    this.choose_father_project.project_name = edit_info.project_name;
                }
            }
            this.tableData = this.oldTableData.filter(a => a.project_name == this.choose_father_project.project_name && a.area == this.choose_father_project.area);
        },
        // 获取子集数量
        getChildCount(list) {
            console.log('list',list);
            const count = list.reduce((acc, cur) => {
                return acc + cur.children.length;
            },0)
            return count
        }
    }
};
</script>

<style scoped lang="less">
//@import "project.less";

.main.project {
    .container {
        > .filter {
            justify-content: space-between;
            padding-bottom: 0;

            .left, .right {
                padding-bottom: 17px;
            }
        }

        .genre_title {
            padding: 20px 0 15px 10px;

            .genre {
                cursor: pointer;
                transition: color .2s;

                &:hover {
                    color: #3396fb;
                }
            }

            > .icon {
                display: inline-block;
                width: 13px;
                height: 13px;
                margin: 0 0 0 10px;
                background-image: url("../../assets/img/icon-right.png");
                background-size: contain;
                background-repeat: no-repeat;
            }

            > .title {
                color: #3396f4;
            }
        }

        .father_table {
            flex: 1;
            min-height: 0;
            overflow-y: auto;
            box-sizing: border-box;
            margin-bottom: 10px;

            .list_item {
                user-select: none;

                .item_genre_title, > .item {
                    display: flex;
                    align-items: center;
                    height: 45px;
                    border: 1px solid #dfdfdf;
                    border-radius: 4px;
                    cursor: pointer;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    padding: 0 15px;
                    transition: background .2s;

                    .title_label, .area {
                        flex: 1;
                        min-width: 0;
                    }

                    &:hover {
                        background: #F5F7FA;
                    }
                }

                .right_icon {
                    width: 12px;
                    height: 12px;
                    background-image: url("../../assets/img/icon-right.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    transition: transform .2s;
                }

                .item_genre_title {
                    background: #f6f7f7;

                    .right_icon {
                        transform: rotate(90deg);
                    }

                    &.open {
                        .right_icon {
                            transform: rotate(0);
                        }
                    }
                }
            }
        }

        .table {
            padding-bottom: 20px;
        }
    }

    .el-table {
        .project_profile {
            overflow: hidden;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
}
</style>